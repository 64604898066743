<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <div class="row">
          <div class="col-12 mb-4">
            <h5>
              <router-link
                :to="{ name: 'order.single', params: { id: order.id } }"
                >Return</router-link
              >
              - Edit order #{{ order.order_number }}
            </h5>
          </div>
          <div class="col-12 mb-6">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group">
                      <label for="customer_note">Customer note</label>
                      <textarea
                        class="form-control"
                        id="customer_note"
                        rows="4"
                        v-model="form.customer_note"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="well mb-0">
                      <p class="mb-1">
                        <span class="font-weight-bold">Subtotal</span>
                        <span
                          class="float-right"
                          v-html="$utils.formatMoney(subTotal, order.currency)"
                        ></span>
                      </p>
                      <p class="mb-1">
                        <span class="font-weight-bold">Discount</span>
                        <span
                          class="float-right"
                          v-html="
                            $utils.formatMoney(discountTotal, order.currency)
                          "
                        ></span>
                      </p>
                      <p class="mb-1">
                        <span class="font-weight-bold">Tax</span>
                        <span
                          class="float-right"
                          v-html="$utils.formatMoney(taxTotal, order.currency)"
                        ></span>
                      </p>
                      <p class="mb-1">
                        <span class="font-weight-bold">Grand Total</span>
                        <span
                          class="float-right"
                          v-html="
                            $utils.formatMoney(grandTotal, order.currency)
                          "
                        ></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-6">
            <div class="card border-0 shadow-sm">
              <div class="card-header bg-dark text-white">
                <p class="mb-0">Items</p>
              </div>
              <transition-group tag="div" class="row" name="slide-fade">
                <div
                  v-for="(item, index) in form.order_items"
                  :key="`item-${index}`"
                  class="col-12"
                >
                  <div class="card-header rounded-0 bg-light border-bottom">
                    <p class="mb-0">
                      {{ item.name }}
                      <span class="float-right">
                        <span class="text-muted mr-3"
                          >Qty. {{ item.qty_ordered }}</span
                        >
                        Total:
                        <span
                          v-html="
                            $utils.formatMoney(item.total, order.currency)
                          "
                        ></span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          class="ml-5"
                          viewBox="0 0 512 512"
                          @click="deleteItem(index, 'product')"
                        >
                          <path
                            d="M452.923 98.462h-98.462C354.462 44.081 310.38 0 256 0s-98.462 44.081-98.462 98.462H59.077c-13.598 0-24.615 11.018-24.615 24.615s11.018 24.615 24.615 24.615h9.846V448c.059 35.328 28.672 63.941 64 64h246.154c35.328-.059 63.941-28.672 64-64V147.692h9.846c13.598 0 24.615-11.018 24.615-24.615s-11.017-24.615-24.615-24.615zM256 49.231c27.185 0 49.231 22.046 49.231 49.231h-98.462c0-27.186 22.046-49.231 49.231-49.231zM393.846 448c0 8.153-6.617 14.769-14.769 14.769H132.923c-8.153 0-14.769-6.617-14.769-14.769V147.692h275.692V448z"
                            fill="#ff485a"
                          />
                          <g fill="#ffbbc0">
                            <path
                              d="M201.846 379.077c-13.598 0-24.615-11.018-24.615-24.615V256c0-13.598 11.018-24.615 24.615-24.615s24.615 11.018 24.615 24.615v98.462c.001 13.597-11.017 24.615-24.615 24.615zM310.154 379.077c-13.598 0-24.615-11.018-24.615-24.615V256c0-13.598 11.018-24.615 24.615-24.615 13.598 0 24.615 11.018 24.615 24.615v98.462c0 13.597-11.018 24.615-24.615 24.615z"
                            />
                          </g>
                        </svg>
                      </span>
                    </p>
                  </div>
                  <div class="card-body">
                    <div class="form-row mb-0">
                      <div class="form-group mb-0 col-md-4">
                        <label for="inputEmail4">Product name</label>
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          v-model="item.name"
                        />
                      </div>
                      <div class="form-group mb-0 col-md-2">
                        <label for="inputPassword4">Qty.</label>
                        <input
                          class="form-control form-control-sm"
                          type="number"
                          min="0"
                          v-model="item.qty_ordered"
                        />
                      </div>
                      <div class="form-group mb-0 col-md-2">
                        <label for="inputPassword4">Price</label>
                        <div class="input-group input-group-sm mb-0">
                          <div
                            class="input-group-prepend"
                            v-if="order.currency.symbol_placement == 'before'"
                          >
                            <div class="input-group-text">
                              {{ order.currency.symbol }}
                            </div>
                          </div>
                          <input
                            class="form-control"
                            type="number"
                            v-model="item.price"
                            placeholder="0"
                          />
                          <div
                            class="input-group-append"
                            v-if="order.currency.symbol_placement == 'after'"
                          >
                            <div class="input-group-text">
                              {{ order.currency.symbol }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0 col-md-2">
                        <label for="inputPassword4">Tax</label>
                        <div
                          class="input-group input-group-sm mb-0"
                          aria-describedby="tax"
                        >
                          <div
                            class="input-group-prepend"
                            v-if="order.currency.symbol_placement == 'before'"
                          >
                            <div class="input-group-text">%</div>
                          </div>
                          <input
                            class="form-control"
                            type="number"
                            v-model="item.tax_percent"
                            placeholder="0"
                          />
                          <div
                            class="input-group-append"
                            v-if="order.currency.symbol_placement == 'after'"
                          >
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                        <small id="tax" class="form-text text-muted"
                          >Total:
                          <span
                            v-html="
                              $utils.formatMoney(
                                item.tax_amount,
                                order.currency
                              )
                            "
                          ></span
                        ></small>
                      </div>
                      <div class="form-group mb-0 col-md-2">
                        <label for="inputPassword4">Discount</label>
                        <div
                          class="input-group input-group-sm mb-0"
                          aria-describedby="tax"
                        >
                          <div
                            class="input-group-prepend"
                            v-if="order.currency.symbol_placement == 'before'"
                          >
                            <div class="input-group-text">%</div>
                          </div>
                          <input
                            class="form-control"
                            type="number"
                            v-model="item.discount_percent"
                            placeholder="0"
                          />
                          <div
                            class="input-group-append"
                            v-if="order.currency.symbol_placement == 'after'"
                          >
                            <div class="input-group-text">%</div>
                          </div>
                        </div>
                        <small id="tax" class="form-text text-muted"
                          >Total:
                          <span
                            v-html="
                              $utils.formatMoney(
                                item.discount_amount,
                                order.currency
                              )
                            "
                          ></span
                        ></small>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
              <div class="card-header rounded-0 bg-dark text-white">
                <p class="mb-0">Shipping</p>
              </div>
              <transition-group tag="div" class="row" name="slide-fade-two">
                <div
                  v-for="(item, index) in form.shipments"
                  :key="`shipment-${index}`"
                  class="col-12"
                >
                  <div class="card-header rounded-0 bg-light border-bottom">
                    <p class="mb-0">
                      {{ item.carrier_title }}
                      <span class="float-right">
                        <span class="text-muted mr-3"
                          >Qty. {{ item.total_qty }}</span
                        >
                        Total:
                        <span
                          v-html="
                            $utils.formatMoney(
                              item.total * item.total_qty,
                              order.currency
                            )
                          "
                        ></span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="16"
                          class="ml-5"
                          viewBox="0 0 512 512"
                          @click="deleteItem(index, 'shipment')"
                        >
                          <path
                            d="M452.923 98.462h-98.462C354.462 44.081 310.38 0 256 0s-98.462 44.081-98.462 98.462H59.077c-13.598 0-24.615 11.018-24.615 24.615s11.018 24.615 24.615 24.615h9.846V448c.059 35.328 28.672 63.941 64 64h246.154c35.328-.059 63.941-28.672 64-64V147.692h9.846c13.598 0 24.615-11.018 24.615-24.615s-11.017-24.615-24.615-24.615zM256 49.231c27.185 0 49.231 22.046 49.231 49.231h-98.462c0-27.186 22.046-49.231 49.231-49.231zM393.846 448c0 8.153-6.617 14.769-14.769 14.769H132.923c-8.153 0-14.769-6.617-14.769-14.769V147.692h275.692V448z"
                            fill="#ff485a"
                          />
                          <g fill="#ffbbc0">
                            <path
                              d="M201.846 379.077c-13.598 0-24.615-11.018-24.615-24.615V256c0-13.598 11.018-24.615 24.615-24.615s24.615 11.018 24.615 24.615v98.462c.001 13.597-11.017 24.615-24.615 24.615zM310.154 379.077c-13.598 0-24.615-11.018-24.615-24.615V256c0-13.598 11.018-24.615 24.615-24.615 13.598 0 24.615 11.018 24.615 24.615v98.462c0 13.597-11.018 24.615-24.615 24.615z"
                            />
                          </g>
                        </svg>
                      </span>
                    </p>
                  </div>
                  <div class="card-body">
                    <div class="form-row mb-0">
                      <div class="form-group mb-0 col-md-4">
                        <label for="inputEmail4">Carrier</label>
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          v-model="item.carrier_title"
                        />
                      </div>
                      <div class="form-group mb-0 col-md-1">
                        <label for="inputPassword4">Qty.</label>
                        <input
                          class="form-control form-control-sm"
                          type="number"
                          min="0"
                          v-model="item.total_qty"
                        />
                      </div>
                      <div class="form-group mb-0 col-md-2">
                        <label for="inputPassword4">Price</label>
                        <div class="input-group input-group-sm mb-0">
                          <div
                            class="input-group-prepend"
                            v-if="order.currency.symbol_placement == 'before'"
                          >
                            <div class="input-group-text">
                              {{ order.currency.symbol }}
                            </div>
                          </div>
                          <input
                            class="form-control"
                            type="number"
                            v-model="item.total"
                            placeholder="0"
                          />
                          <div
                            class="input-group-append"
                            v-if="order.currency.symbol_placement == 'after'"
                          >
                            <div class="input-group-text">
                              {{ order.currency.symbol }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
              <div class="card-footer">
                <div class="row">
                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-outline-primary mr-2"
                      @click="addNewItem('product')"
                    >
                      Add product
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-primary mr-2"
                      @click="addNewItem('fee')"
                    >
                      Add fee
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-primary mr-2"
                      @click="addNewItem('shipment')"
                    >
                      Add shipping
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-6">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <editable-address
                  v-if="form.billing_address"
                  :title="'Billing'"
                  :address="form.billing_address"
                ></editable-address>
              </div>
            </div>
          </div>
          <div class="col-12 mb-6">
            <div class="card border-0 shadow-sm">
              <div class="card-body">
                <editable-address
                  v-if="form.shipping_address"
                  :title="'Shipping'"
                  :address="form.shipping_address"
                ></editable-address>
              </div>
            </div>
          </div>
          <div class="col-12">
            <ButtonLoading
              :loading="loading"
              :title="'Update order'"
              :class="'btn btn-primary float-right btn-lg'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import EditableAddress from "@/components/UI/EditableAddress";
import countries from "@/helpers/countries";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "OrderView",
  components: {
    Default,
    EditableAddress,
    ButtonLoading,
  },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("order/" + _id).then((response) => {
      next((vm) => {
        vm.order = response.data;
        vm.form = {
          id: _id,
          customer_note: vm.order.customer_note,
          billing_address: vm.order.billing_address,
          shipping_address: vm.order.shipping_address,
          order_items: vm.order.order_items,
          shipments: vm.order.shipments,
        };
      });
    });
  },
  data() {
    return {
      layout: "Default",
      loading: false,
      order: {},
      form: {},
      countries: countries,
    };
  },
  computed: {
    subTotal: function() {
      let shipmentTotal = 0;
      if (this.form.shipments) {
        shipmentTotal = this.form.shipments.reduce(function(accumulator, item) {
          return accumulator + item.total * item.total_qty;
        }, 0);
      }

      if (this.form.order_items) {
        return (
          this.form.order_items.reduce(function(accumulator, item) {
            return accumulator + item.price * item.qty_ordered;
          }, 0) + shipmentTotal
        );
      }

      return 0;
    },
    discountTotal: function() {
      if (this.form.order_items) {
        return this.form.order_items.reduce(function(accumulator, item) {
          return accumulator + item.discount_amount;
        }, 0);
      }

      return 0;
    },
    taxTotal: function() {
      if (this.form.order_items) {
        return this.form.order_items.reduce(function(accumulator, item) {
          return accumulator + item.tax_amount;
        }, 0);
      }

      return 0;
    },
    grandTotal: function() {
      return this.subTotal - this.discountTotal + this.taxTotal;
    },
  },
  methods: {
    addNewItem: function(type) {
      if (type === "product") {
        this.form.order_items.push({
          name: "Product name",
          qty_ordered: 1,
          price: "0.0000",
          discount_percent: "0.0000",
          discount_amount: "0.0000",
          tax_percent: "0.0000",
          tax_amount: "0.0000",
        });
      } else if (type === "fee") {
        this.form.order_items.push({
          name: "Fee",
          qty_ordered: 1,
          price: "0.0000",
          discount_percent: "0.0000",
          discount_amount: "0.0000",
          tax_percent: "0.0000",
          tax_amount: "0.0000",
        });
      } else if (type === "shipment") {
        this.form.shipments.push({
          total: "0.0000",
          total_qty: 1,
          carrier_code: null,
          carrier_title: "Carrier title",
        });
      }
      window.Bus.$emit("flash-message", {
        text: "Added order item",
        type: "success",
      });
    },
    deleteItem: function(index, type) {
      if (type === "product") {
        this.form.order_items.splice(index, 1);
      } else if (type === "shipment") {
        this.form.shipments.splice(index, 1);
      }
      window.Bus.$emit("flash-message", {
        text: "Deleted order item",
        type: "success",
      });
    },
    submitForm: function() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .put("/order/" + this.order.id, this.form)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "Order updated",
                type: "success",
              });
              this.loading = false;
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "Could not update order",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
    discountAmount(item) {
      return (item.discount_amount =
        (item.total * item.discount_percent) / 100);
    },
  },
};
</script>
<style type="text/css">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-two-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-two-leave-active {
  transition: all 0s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-two-enter,
.slide-fade-two-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
